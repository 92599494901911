.expandedTable {
    color: #0b2e13;
    border-collapse: collapse;
}

.expandedTable th, .expandedTable td{
    border: 1px solid #eaeaea;
    padding-right: 1em;
}

.header {
    text-align: center;
}

.number, .sum, .popis {
    text-align: right;
}

.sum:after {
    content: '€';
}