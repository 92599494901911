.reset-password-container {
    max-width: 420px;
    margin: 0 auto;
    margin-top: 40px;
}

.reset-password-form-button {
    width: 100%;
    margin-top: 15px;
}
