.new-menu-container, .edit-menu-container {
    max-width: 640px;
    margin: 0 auto;
    margin-top: 40px;
}

.menu-form-row {
    margin-bottom: 20px;
}

.menu-form-row input {
    margin-bottom: 4px;
}

.tag-list {
    margin-bottom: 10px;
}

