.serve-container {
    min-width: 1024px;
    margin: 0 auto;
    margin: 0 auto;
    margin-top: 40px;
}

.serve-name{
    text-align: left;
    font-size: 20pt;
}

.serve-rov{
    text-align: left;
    margin-top: 15px;
}

.serve-itemId{
    text-align: center;
    font-size: 220pt;
    font-weight: bold;
    margin-top: -70px;
}

.serve-itemQuantity{
    text-align: center;
    font-size: 15pt;
    color: #fa8c16;
    font-weight: bold;
    margin-top: -140px;
}

.serve-description{
    text-align: left;
    font-size: 18pt;
}

.progress-bar {
    margin-top: 15px;
}

.progress-bar-num {
    text-align: center;
    width: 120px;
    font-size: large;
    font-weight: bold;
}

.serve-alert {
    margin-bottom: 5px;
    margin-right: 5px;
}
