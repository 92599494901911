.menu-rov-container {
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 50px;
}

.menu-ordered{
    background: #ebffe4;
}

.menu-item .menu-item{
    background: white;
}

.menu-item.menu-day-2, .menu-item.menu-day-4 {
    background-color: #f0f0f0;
}

td.menu-itemid {
    text-align: center;
    font-weight: bold;
    font-size: xx-large;
}

.itemid-badge {
    text-align: center;
    font-weight: bold;
    font-size: xx-large;
    width: 1.4em;
}

.menu-obed .menu-rov {
    font-size: medium;
}

.menu-action {
    width: 120px;
}

.menu-description {
    width: 50%;
}

.menu-day-2.menu-obed.menu-itemid--  td.menu-date {
    font-size: larger;
}

.menu-date:first-letter {
    text-transform: uppercase;
}

.no-menu-rov-found {
    font-size: 20px;
    text-align: center;
    padding: 20px;
}

.drawer {
    height: calc(100% - 55px);
    overflow: auto;
    paddingBottom: 53;
}
