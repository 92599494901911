.signup-container {
    max-width: 420px;
    margin: 0 auto;
    margin-top: 40px;    
}

.signup-form-button {
    width: 100%;
    margin-top: 15px;
}

.signup-form-button[disabled], .signup-form-button[disabled]:hover, .signup-form-button[disabled]:focus {
    opacity: 0.6;
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
}

.signup-form input {
    margin-bottom: 3px;
}

.forgot-pwd {
    float: right;
}