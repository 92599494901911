.serve-form-container{
    text-align: left;
    margin-bottom: 5px;
}

.serve-form-button {
    width: 120px;
}

.serve-form-button-status {
    width: 100px;
    color: grey;
}

