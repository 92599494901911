.login-container {
    max-width: 420px;
    margin: 0 auto;
    margin: 0 auto;
    margin-top: 40px;
}

.login-form-button {
    width: 100%;
}

.forgot-pwd {
    float: right;
}