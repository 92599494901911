.expandedTable {
    color: #0b2e13;
    border-collapse: collapse;
}

.expandedTable th, .expandedTable td{
    border: 1px solid #eaeaea;
    padding-right: 1em;
}

.header {
    text-align: center;
}

.popis, .riadok {
    text-align: center;
}
