.new-credit-container {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20px;
}

.credit-form-row {
    margin-bottom: 20px;
}

.credit-form-row input {
    margin-bottom: 4px;
}

