.credit-príjem {
    background-color: rgba(180, 245, 18, 0.47);
}

.credit-storno {
    background-color: rgba(220, 254, 18, 0.18);
}

.credit-výdaj {
    background-color: rgba(244, 239, 223, 0.47);
}

.credit {
    background-color: #5a6268;
    color: #dfdfdf;
}