.user-list-container {
    max-width: 1600px;
    margin: 0 auto;
}

.drawer {
    height: calc(100% - 55px);
    overflow: auto;
    paddingBottom: 53;
}
